import { Injectable } from '@angular/core'
import { ModelTypes } from '@repo/models'
import { ReqOptions, RequestService, ValidResponse } from 'app/_services/request.service'
import { Observable, of } from 'rxjs'

type CrudResponse<T> = Observable<ValidResponse<T>>
@Injectable({ providedIn: 'root' })
export class CrudService {
    urlBase = `/model/`

    constructor(private requestService: RequestService) {}

    new<Key extends keyof ModelTypes>(modelNameKebap: Key, instance, reqOpts: ReqOptions = null): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/crud/new`
        const body = { [modelNameKebap]: instance }
        return this.requestService.post(url, body, {}, reqOpts)
    }

    create<Key extends keyof ModelTypes>(modelNameKebap: Key, instance, reqOpts: ReqOptions = null): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/crud`
        const body = { [modelNameKebap]: instance }
        return this.requestService.post(url, body, {}, reqOpts)
    }

    read<Key extends keyof ModelTypes>(
        modelNameKebap: Key,
        instanceId: string = null,
        reqOpts: ReqOptions = null
    ): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/crud${instanceId ? `/${instanceId}` : ''}`
        return this.requestService.get(url, {}, reqOpts)
    }

    update<Key extends keyof ModelTypes>(
        modelNameKebap: Key,
        instanceId: string,
        data,
        reqOpts: ReqOptions = null
    ): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/crud/${instanceId}`
        const body = { [modelNameKebap]: data }
        return this.requestService.patch(url, body, {}, reqOpts)
    }

    delete<Key extends keyof ModelTypes>(
        modelNameKebap: Key,
        instanceId,
        c = 'Weet je zeker dat je dit wilt verwijderen?',
        reqOpts: ReqOptions = null
    ) {
        // Ask confirm message if provided
        const notConfirmed = c && !confirm(c)
        if (notConfirmed) return of(undefined)

        // Do call
        const url = `${this.urlBase}${modelNameKebap}/crud/${instanceId}`
        return this.requestService.delete(url, {}, reqOpts)
    }

    archive<Key extends keyof ModelTypes>(modelNameKebap: Key, instanceId, reqOpts: ReqOptions = null): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/crud/${instanceId}/archive`
        return this.requestService.delete(url, {}, reqOpts)
    }

    unarchive<Key extends keyof ModelTypes>(modelNameKebap: Key, instanceId, reqOpts: ReqOptions = null): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/crud/${instanceId}/unarchive`
        return this.requestService.patch(url, {}, {}, reqOpts)
    }

    distinct<Key extends keyof ModelTypes>(modelNameKebap: Key, field, reqOpts: ReqOptions = null): Observable<ValidResponse<string[]>> {
        const url = `${this.urlBase}${modelNameKebap}/distinct/${field}`
        return this.requestService.get(url, {}, reqOpts)
    }

    duplicate<Key extends keyof ModelTypes>(modelNameKebap: Key, instanceId, reqOpts: ReqOptions = null): CrudResponse<ModelTypes[Key]> {
        const url = `${this.urlBase}${modelNameKebap}/duplicate/${instanceId}`
        return this.requestService.get(url, {}, reqOpts)
    }
}
